import React from 'react'

import './book.css'

import { Card, Image, Header, Label } from 'semantic-ui-react'

interface Props {
  bookIndex: number
  isNew?: boolean
}

interface Book {
  title: string
  subtitle?: string
  edition?: string
  publisher: string
  date: string
  cover: string
  purchaseLink: string
}

const Books: Book[] = [
  { title: 'Circuit Design with VHDL', edition: '3rd', publisher: 'MIT Press', date: 'March 2020', cover: 'cover0.jpg', purchaseLink: 'https://www.amazon.com/Circuit-Design-VHDL-MIT-Press/dp/0262042649/ref=sr_1_2?keywords=volnei+pedroni&qid=1584127572&sr=8-2' },
  { title: 'Circuit Design with VHDL', edition: '1st', publisher: 'MIT Press', date: '2004', cover: 'cover1.jpg', purchaseLink: '' },
  { title: 'Circuit Design and Simulation with VHDL', edition: '2nd', publisher: 'MIT Press', date: '2010', cover: 'cover2.jpg', purchaseLink: '' },
  { title: 'Finite State Machines in Hardware', subtitle: 'Theory and Design (with VHDL and SystemVerilog)', publisher: 'MIT Press', date: '2013', cover: 'cover3.jpg', purchaseLink: 'https://www.amazon.com/Finite-State-Machines-Hardware-SystemVerilog/dp/0262019663/ref=sr_1_1?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=volnei+pedroni&qid=1584127501&sr=8-1' },
]

const Book: React.FC<Props> = ({ bookIndex, isNew }) => {

  if (bookIndex >= Books.length) throw new Error('Bad book index')

  const book = Books[bookIndex]

  return (
    <Card className="book" href={book.purchaseLink} target="_blank">

      <Image src={`../../${book.cover}`} />

      {isNew && <span className="new-label">NEW!</span>}

      <Card.Content>
        <Card.Header>
          {book.title}
          {book.subtitle && <Header.Subheader as="h6">{book.subtitle}</Header.Subheader>}
        </Card.Header>
        <Card.Meta>
          {book.edition && <Header as="h5">{book.edition} edition</Header>}
        </Card.Meta>
        <Card.Description>
          Volnei A. Pedroni<br />
          {book.publisher}, {book.date}
        </Card.Description>

      </Card.Content>      

    </Card>
  )
}

export default Book